'use client'

import IframeResizer, {
  type IFrameComponent,
  type IFrameObject,
} from '@iframe-resizer/react'
import {delay, kebabCase} from 'lodash'
import {useSearchParams} from 'next/navigation'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigationHistory} from '~/app/_components/NavigationHistoryProvider'
import {useSignupAnalytics} from '~/components/SignupAnalytics'
import {env} from '~/config/env.mjs'
import {mercuryFormstackUrl} from '~/data/mercury'
import {HgLoadingDots} from '~/design-system/hg/components'
import {type ThemeClassName} from '~/design-system/hg/tokens/colors'
import {Analytics} from '~/utils/Analytics'
import {hasLoggedInCookie} from '~/utils/hasLoggedInCookie'

type FormstackFormProps = {
  formstackName: string
}

// NB: If you are planning to use this component, please make sure that the @iframe-resizer/child lib
// has been loaded into the child iframe. See more here: https://iframe-resizer.com/frameworks/react/
const FormstackForm = ({formstackName}: FormstackFormProps) => {
  const [theme, setTheme] = useState<ThemeClassName>()
  const iRef = useRef<IFrameObject | null>(null)
  const {data: signupAnalytics} = useSignupAnalytics()
  const {getPreviousURL} = useNavigationHistory()
  const searchParams = useSearchParams()
  const [formstackUrl, setFormstackUrl] = useState<string>('')

  const generateFormstackUrl = useCallback(() => {
    const formstackUrl = new URL(`${mercuryFormstackUrl}/forms/${formstackName}`)
    const analyticsEntries =
      signupAnalytics?.tag === 'data'
        ? {
            utm_campaign: signupAnalytics.contents.campaign || '',
            utm_source: signupAnalytics.contents.source || '',
            utm_medium: signupAnalytics.contents.medium || '',
            utm_term: signupAnalytics.contents.term || '',
            utm_content: signupAnalytics.contents.content || '',
            referrer_url: signupAnalytics.contents.referrerUrl || '',
          }
        : {}

    // Misc. info that might be useful to know
    const generalEntries = {
      last_visited_page: getPreviousURL()?.split('?')[0] || '',
      is_logged_in: hasLoggedInCookie(),
    }

    // Formstack settings
    const formstackSettings = {
      // Disable autofocus & scroll on initial render
      shouldAutoFocus: 0,
    }

    const formstackParams = new URLSearchParams([
      ...Object.entries(analyticsEntries),
      ...Object.entries(generalEntries),
      ...Object.entries(formstackSettings),
      // assume that whatever is in current search params is latest/ greatest
      ...Array.from(searchParams.entries()),
    ])

    formstackUrl.search = formstackParams.toString()

    return formstackUrl.toString()
  }, [formstackName, getPreviousURL, searchParams, signupAnalytics])

  useEffect(() => {
    setFormstackUrl(generateFormstackUrl())
  }, [generateFormstackUrl])

  const useMutationObserver = (
    callback: MutationCallback,
    options = {
      attributes: true,
      attributeFilter: ['data-global-theme'],
    }
  ) => {
    useEffect(() => {
      const target = document.getElementById('theme-switch-scroller')
      if (target) {
        const observer = new MutationObserver(callback)
        observer.observe(target, options)
        return () => {
          observer.disconnect()
        }
      }
    }, [callback, options])
  }

  const handleThemeChange = useCallback((mutations: MutationRecord[]) => {
    const targetDiv = mutations[0].target as HTMLDivElement
    const newTheme = targetDiv.dataset.globalTheme as ThemeClassName
    setTheme(prevTheme => (prevTheme !== newTheme ? newTheme : prevTheme))
  }, [])

  useMutationObserver(handleThemeChange)

  useEffect(() => {
    iRef.current?.sendMessage(theme || 'neutral-theme')
  }, [theme])

  const handleChildMessage = ({
    message,
    iframe,
  }: {
    message: any
    iframe: IFrameComponent
  }) => {
    if (message?.type === 'submit' && message?.isSuccessful) {
      Analytics.trackClick({
        type: kebabCase(formstackName),
        location: 'webform-module',
      })

      // Only set max height if success screen is shown
      // Automatic resizing doesn't work on submit, so enforcing a max height to avoid a very tall iframe
      iframe.style.maxHeight = '500px'

      if (message?.theme) {
        // Hacky, but we don't have a reliable way of knowing when new iframe document has been loaded
        // after submit, so just spam w/ current theme to ensure it gets set
        for (let i = 0; i < 50; i++) {
          delay(() => {
            iRef.current?.sendMessage(message.theme || 'neutral-theme')
          }, i * 15)
        }
      }
    }
  }

  // Formstack form will noticeably rerender if we update the url w/ search params - so show loading dots until
  // complete url is generated
  if (!formstackUrl) {
    return <HgLoadingDots className="justify-center" />
  }

  return (
    <IframeResizer
      license={env.NEXT_PUBLIC_IFRAME_RESIZER_LICENSE_KEY}
      src={formstackUrl}
      style={{width: '100%', height: '100%'}}
      className="rounded-xl shadow-[0px_0px_3px_0px_#1C1C2217,0px_6px_10px_0px_#1C1C220A,0px_10px_16px_0px_#1C1C2205]"
      forwardRef={iRef}
      scrolling
      onMessage={handleChildMessage}
    />
  )
}

export default FormstackForm
