import {type AspectRatio} from '~/design-system/hg/components/HgAspectRatio'
import {DatoImage} from '../DatoImage'
import DatoAnimation from '../DatoVideo'
import {
  type ThreeTwoAssetFragment,
  type OneOneAssetFragment,
  type TwoOneAssetFragment,
  type FourFiveAssetFragment,
  type SixteenNineAssetFragment,
  type SixteenFiveAssetFragment,
} from './_generated/fragments.graphql'

const Asset = ({
  data,
  aspectRatio,
}: {
  data:
    | TwoOneAssetFragment
    | OneOneAssetFragment
    | ThreeTwoAssetFragment
    | FourFiveAssetFragment
    | SixteenNineAssetFragment
    | SixteenFiveAssetFragment
  aspectRatio: AspectRatio
}) => {
  const {asset} = data
  switch (asset.__typename) {
    case 'ImageBlockRecord': {
      return <DatoImage layout="fill" objectFit="cover" data={asset} />
    }
    case 'VideoBlockRecord':
      return <DatoAnimation data={asset} aspectRatio={aspectRatio} />
    default:
      return null
  }
}

export default Asset
