'use client'

import NextVideo from 'next-video'
import Image from 'next/image'
import React, {useRef} from 'react'
import {HgAnimation, HgImage} from '~/design-system/hg/components'
import {type AspectRatio} from '~/design-system/hg/components/HgAspectRatio'
import {themeToClassName, type Theme} from '~/design-system/hg/tokens/colors'
import {useIsVisible} from '~/design-system/hooks/useIsVisible'
import {notifyBugsnag} from '~/utils/Bugsnag/notify'
import {type DatoVideoFragment} from './_generated/fragments.graphql'

const DatoVideo = React.forwardRef<
  HTMLVideoElement,
  React.ComponentPropsWithoutRef<typeof NextVideo>
>((props, ref) => {
  return <NextVideo {...props} ref={ref} min-resolution="480p" />
})

DatoVideo.displayName = 'DatoVideo'

type DatoAnimationProps = {
  data: DatoVideoFragment
  aspectRatio: AspectRatio
}

const DatoAnimation = ({data, aspectRatio}: DatoAnimationProps) => {
  const assetRef = useRef<HTMLDivElement>(null)
  const showVideo = useIsVisible(assetRef, {
    rootMargin: '100%',
    once: true,
  })
  const controlsTheme = (
    data.controlsTheme && data.controlsTheme.name in themeToClassName
      ? data.controlsTheme.name
      : 'neutral'
  ) as Theme

  if (!data?.video?.video) {
    notifyBugsnag('Dato video asset missing video data', {
      metadata: {data},
    })
    return null
  }

  const {muxPlaybackId: playbackId, thumbnailUrl} = data.video.video
  // use either dato-provided poster or generate one from video thumbnail
  const {
    width: posterWidth,
    height: posterHeight,
    url: posterUrl,
    blurUpThumb,
  } = data.poster?.url
    ? data.poster
    : {...data.video.video, url: `${thumbnailUrl}?time=0`}

  const handlePlayError = (error: unknown) => {
    if (error instanceof DOMException) {
      // Ignore common errors from browsers not allowing videos to play
      if (
        ['NotAllowedError', 'AbortError', 'NotSupportedError'].includes(error.name)
      ) {
        return
      }
    }

    notifyBugsnag('Unable to play Dato video', {
      caughtError: error,
    })
  }

  return (
    <div ref={assetRef} className="grid">
      {blurUpThumb && (
        <HgImage className="col-start-1 col-end-auto row-start-1 row-end-auto">
          <Image
            src={blurUpThumb}
            alt=""
            width={posterWidth}
            height={posterHeight}
          />
        </HgImage>
      )}
      {showVideo && (
        <div className="col-start-1 col-end-auto row-start-1 row-end-auto">
          <div className="grid">
            <HgAnimation
              aspectRatio={aspectRatio}
              controlsTheme={controlsTheme}
              showVolumeButton={!!data.showVolumeButton}
              asset={
                <DatoVideo playbackId={playbackId}>
                  <Image
                    slot="poster"
                    src={posterUrl}
                    placeholder="blur"
                    blurDataURL={blurUpThumb || undefined}
                    alt=""
                    width={posterWidth}
                    height={posterHeight}
                  />
                </DatoVideo>
              }
              onPlayError={handlePlayError}
              preload="auto"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DatoAnimation
