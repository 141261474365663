// cspell:disable
import {Method, SimpleAPI} from '../../SimpleAPI'

type HelpCenterGetArticlesResponse = {
  result: HelpCenterArticle[]
}

export type HelpCenterArticle = {
  pageSlug: {
    current: string
  }
}

export class HelpCenterGetArticles extends SimpleAPI<
  HelpCenterGetArticlesResponse,
  any
> {
  revalidate = 'on-demand' as const
  method = Method.POST

  getPath() {
    return '/sanity/logged-out'
  }

  protected preProcessPostParams() {
    return {
      documentType: 'helpCenter',
    }
  }

  protected async getMockResponse(): Promise<HelpCenterGetArticlesResponse> {
    return {result: [{pageSlug: {current: 'test-article'}}]}
  }
}
